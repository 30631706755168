import { viewStorage, globalStorage, domStorage } from '../_globals'

export default class EBook{
  constructor() {
    this.DOM = { ebook: viewStorage.current.querySelector('.Popup#EBook') }

    if (!this.DOM.ebook) return

    // DOM
    this.DOM.openButton = viewStorage.current.querySelector('.EBookBtn'),
    this.DOM.closeButton = this.DOM.ebook.querySelector('.Close')

    // Events
    this.showPopup = this.showPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)

    if (this.DOM.openButton) {
      this.DOM.openButton.addEventListener('click', this.showPopup)
    }

    if (this.DOM.closeButton) {
      this.DOM.closeButton.addEventListener('click', this.closePopup)
    }

    // Display the popup after several seconds (depending on firstLoad)
    setTimeout(() => this.showPopup(), globalStorage.firstLoad ? 6000 : 2500)
  }

  showPopup() {
    // Add classNames
    domStorage.body.classList.add('--show-ebook')
    domStorage.header.classList.add('--hide')

    // Stop smooth scroll
    if (viewStorage.hasSmoothScroll) viewStorage.viewScroll.scroll.stop()
  }

  closePopup(e) {
    e && e.preventDefault()

    // Remove classNames
    domStorage.body.classList.remove('--show-ebook')
    domStorage.header.classList.remove('--hide')

    // Start smooth scroll
    if (viewStorage.hasSmoothScroll) viewStorage.viewScroll.scroll.start()
  }

  destroy() {
    console.log('destroy ebook')

    if (!domStorage.ebook) return

    // Remove classNames
    domStorage.body.classList.remove('--show-ebook')
    domStorage.header.classList.remove('--hide')

    if (this.DOM.openButton) {
      this.DOM.openButton.addEventListener('click', this.showPopup)
    }

    if (this.DOM.closeButton) {
      this.DOM.closeButton.removeEventListener('click', this.close)
    }
  }
}
