import { isDEVMODE } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'

export default class ThematicsPrllx {
  constructor(section) {
    if (!section) return

    // DOM
    this.DOM = { section }
    this.DOM.cards = this.DOM.section.querySelectorAll('.Thematics__card')

    this.init()
  }

  init() {
    if (!this.DOM.cards || !this.DOM.cards.length) return

    const { cards } = this.DOM
    let offsetY = 0

    cards.forEach((card, i) => {
      this.setCardPosition(card)
      offsetY += cards[i - 1] ? cards[i - 1].offsetHeight + gsap.utils.random(0, window.innerHeight * 0.5) : 0
    })

    // Add Last One
    offsetY += cards[cards.length - 1].offsetHeight + gsap.utils.random(0, window.innerHeight * 0.5)

    cards.forEach((card, i) => {

      card.TL = gsap.to(card, {
        y: `+=${offsetY}`,
        duration : cards.length * 3,
        ease: 'none',
        modifiers: {
          y: function(y) {
            const newY = gsap.utils.wrap(0, offsetY, parseFloat(y))
            return `${newY}px`
          }
        },
        repeat: -1,
        onRepeat: () => this.setCardPosition(card)

      }, 0)

      card.TL.reverse(0)
      card.TL.progress((1 / cards.length) * i)

    })
  }

  setCardPosition(card) {
    gsap.set(card, {
      x: gsap.utils.random(0, window.innerWidth - card.offsetWidth),
      zIndex: gsap.utils.random([0, 100])
    })
  }

  destroy() {
    if (this.DOM.cards || this.DOM.cards.length) this.DOM.cards.forEach((card, i) => card.TL.kill())
  }
}
