import Plyr from 'plyr'
import WknPlyr from '../modules/wkn-plyr'

export default class WysiwygWithVideos {
  constructor(container) {
    this.DOM = { container }
    

    this.DOM.iframes = this.DOM.container.querySelectorAll('iframe[title="YouTube video player"]')
    this.DOM.videos = this.DOM.container.querySelectorAll('.wp-block-video video')

    if (!this.DOM.iframes.length && !this.DOM.videos) return
    
    this.plyrs = []

    this.setPlayers()
  }

  setPlayers() {
    const { iframes, videos } = this.DOM

    iframes.forEach((iframe, index) => {
      const plyrTemplate = `
        <div class="plyr__container">
          <div class="plyr__video-embed" id="plyr__${index}">
            <iframe
              src="${iframe.getAttribute('src')}"
              allowfullscreen
              allowtransparency
              allow="autoplay"
            >
            </iframe>
          </div>
        </div>
      `

      iframe.parentNode.insertAdjacentHTML('beforeend', plyrTemplate)
      iframe.parentNode.removeChild(iframe)

      const plyr = new Plyr(`#plyr__${index}`, {
        controls: ['play-large', 'play', 'progress', 'mute', 'fullscreen'],
        youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }
      })

      this.plyrs.push(plyr)
    })

    videos.forEach((video) => {

      const plyrTemplate = `
        <div class="plyr__container" >
          <video data-plyr preload="metadata">
            <source src="${video.getAttribute('src')}" type='video/mp4' />
          </video>
          <div class="plyr__cover"></div>
          <button class="plyr__play">
            <div class="Icon" name="play">
              <svg width="12" height="15" viewBox="0 0 12 15" fill="none">
                <path d="M11.5 6.63397C12.1667 7.01888 12.1667 7.98112 11.5 8.36602L1.75 13.9952C1.08333 14.3801 0.249999 13.899 0.249999 13.1292L0.25 1.87083C0.25 1.10103 1.08333 0.619909 1.75 1.00481L11.5 6.63397Z" />
              </svg>
            </div>
          </button>
        </div>
      `
      const videoParent = video.parentNode
      videoParent.insertAdjacentHTML('beforeend', plyrTemplate)
      videoParent.removeChild(video)
      const plyr = new WknPlyr(videoParent.querySelector('.plyr__container'))
      this.plyrs.push(plyr)
    })

  }

  destroy() {
    const { plyrs } = this
    if (!plyrs) return
    if (plyrs.length) plyrs.forEach(plyr => plyr.destroy())
  }
}
