import { globalStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

// import { homeIntro } from './home-intro'
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const introPascalineGrave = domStorage.intro.querySelector('.PascalineGrave')
  const introVisuals = domStorage.intro.querySelectorAll('.gallery .visual__container')

  const rotateFrom = [ -2, 1.5, -4.3, 4.4, -3.3 ]
  const rotateTo = [ 2, -1.5, 4.3, -4.4, 3.3 ]

  // Update body classNames
  domStorage.body.classList.remove('--preloading')
  domStorage.body.classList.remove('--loading')

  // Reset scroll with timing for Chrome
  setTimeout(() => window.scrollTo(0, 0), 25)

  const tl = gsap.timeline({
    defaults: { ease: 'expo.inOut', duration: 1.5 },
    delay: 0.2,
    onComplete: () => {
      // Update firstLoad variable
      globalStorage.firstLoad = false

      // Remove intro from the DOM
      // domStorage.intro.remove()
    }
  })

  tl.fromTo(introPascalineGrave, {
    opacity: 0,
  }, {
    opacity: 1
  }, 0)

  if (introVisuals.length) {
    tl.fromTo(introVisuals, {
      yPercent: (i, el, els) => i % 2 === 0 ? -100 : 100,
      xPercent: (i, el, els) => i % 2 === 0 ? 100 : -100,
      opacity: 0,
      rotate: (i, el, els) => rotateFrom[i]
    }, {
      yPercent: -50,
      xPercent: -50,
      opacity: 1,
      rotate: (i, el, els) => rotateTo[i],
      stagger: { each: 0.15 },
      ease: 'expo.out',
      duration: 1
    })
  }

  tl.to(domStorage.intro, {
    yPercent: -100,
  })

  tl.add(pageIntro(1.9), 0)

    return tl
}
