import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class PrestationDetails {
  constructor({ section }) {
    if (!section) return

    // DOM 
    this.DOM = { section }
    this.DOM.visualScroll = this.DOM.section.querySelector('.visual__scroll')
    this.DOM.visual = this.DOM.section.querySelector('.visual__container')
    this.DOM.placeholderFirst = this.DOM.section.querySelector('.placeholder.--first')
    this.DOM.placeholderSecond = this.DOM.section.querySelector('.placeholder.--second')
    this.DOM.container = this.DOM.section.querySelector('.container')

    if (!this.DOM.placeholderFirst || !this.DOM.placeholderSecond) return

    this.timeline = null
    this.breakpoint = 768

    // Events
    this.createTimeline()
    window.addEventListener('resize', this.onResize.bind(this))    
  }

  createTimeline() {
    if (this.timeline) {
      this.timeline.kill()
      gsap.set(this.DOM.visual, { clearProps: 'all' })
    }

    console.log(window.innerWidth)
    console.log(this.breakpoint) 
    console.log(window.innerWidth < this.breakpoint)

    if (window.innerWidth < this.breakpoint) return

    this.visualSettings = {
      topDistance: this.DOM.visual.getBoundingClientRect().top,
      leftDistance: this.DOM.visual.getBoundingClientRect().left,
      width: this.DOM.visual.offsetWidth,
      height: this.DOM.visual.offsetHeight
    }

    this.placeholderFirstSettings = {
      topDistance: this.DOM.placeholderFirst.getBoundingClientRect().top,
      leftDistance: this.DOM.placeholderFirst.getBoundingClientRect().left,
      width: this.DOM.placeholderFirst.offsetWidth,
      height: this.DOM.placeholderFirst.offsetHeight
    }
    
    this.placeholderSecondSettings = {
      topDistance: this.DOM.placeholderSecond.getBoundingClientRect().top,
      leftDistance: this.DOM.placeholderSecond.getBoundingClientRect().left,
      width: this.DOM.placeholderSecond.offsetWidth,
      height: this.DOM.placeholderSecond.offsetHeight
    }

    gsap.set(this.DOM.visual, {
      y: this.placeholderFirstSettings.topDistance - this.visualSettings.topDistance,
      x: this.placeholderFirstSettings.leftDistance - this.visualSettings.leftDistance,
      width: this.placeholderFirstSettings.width,
      height: this.placeholderFirstSettings.height
    })

    this.timeline = gsap.timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: this.DOM.section,
        start: '0% 0%',
        end: '50% 0%',
        scrub: true
      }
    })

    // Only to make the sticky effect
    const childHeight = this.DOM.placeholderFirst.offsetHeight
    const containerHeight = this.DOM.container.offsetHeight
    const childRect = this.DOM.placeholderFirst.getBoundingClientRect()
    const containerRect = this.DOM.container.getBoundingClientRect()
    const initialPosition = childRect.top - containerRect.top
    const distance = containerHeight - childHeight - initialPosition

    this.timeline.to(this.DOM.visual, {
      y: distance,
      duration: 2
    }, 0)
    
    // Change visual dimensions
    this.timeline.to(this.DOM.visual, {
      y: this.placeholderSecondSettings.topDistance - this.visualSettings.topDistance,
      x: this.placeholderSecondSettings.leftDistance - this.visualSettings.leftDistance,
      width: this.placeholderSecondSettings.width,
      height: this.placeholderSecondSettings.height,
      clipPath: 'inset(0 0 0 round 0 0 0)'
    })
  }

  onResize() {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => this.createTimeline(), 250)
  }

  destroy() {
    if (this.timeline) {
      this.timeline.kill()
      window.removeEventListener('resize', this.onResize)
    }
  }
}
