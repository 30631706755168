import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'

export default class Archive extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      relatedArticlesSwiper: viewStorage.current.querySelector('.RelatedArticles .swiper.--mobile')
    }

    // Modules
    if (this.DOM.relatedArticlesSwiper) this.relatedArticlesSwiperModule = new MobileSwiper(this.DOM.relatedArticlesSwiper)
  }

  onLeaveCompleted() {
    if (this.relatedArticlesSwiperModule) this.relatedArticlesSwiperModule.destroy()
  }
}
