import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class DiscoverPrestations {
  constructor({ section }) {
    if (!section) return

    // DOM
    this.DOM = { section }
    this.DOM.galleryVisuals = this.DOM.section.querySelectorAll('.Discover__gallery .visual__container')

    if (!this.DOM.galleryVisuals || !this.DOM.galleryVisuals.length) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setAnimation()
  }

  setAnimation() {
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        const tl = gsap.timeline({
          defaults: { ease: 'none' },
          scrollTrigger: {
            trigger: this.DOM.section,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        })

        tl.to(this.DOM.galleryVisuals, {
          yPercent: (i, el, els) => {
            return i <= 0 ? -30 : 45
          },
          rotate: (i, el, els) => {
            return i <= 0 ? -5 : 7
          },
          scale: (i, el, els) => {
            return i <= 0 ? 0.9 : 1.05
          }
        }, 0)
      }
    })
  }

  destroy() {
    if (this.mm) this.mm.revert()
  }
}