import { domStorage, viewStorage } from '../_globals';
import Lenis from '@studio-freight/lenis'
import { isTouch } from '../utils'

export default class viewScroll {
  constructor() {
    this.DOM = {
      container: domStorage.body.querySelector('[data-scroll-container]')
    }

    this.init()

    viewStorage.hasSmoothScroll = true
  }

  init() {
    this.scroll = new Lenis({
      duration: 0.82,
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
      smoothWheel: true,
      orientation: 'vertical'
    })

    const raf = (time) => {
      this.scroll.raf(time)
      this.raf = requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  }

  destroy() {
    window.cancelAnimationFrame(this.raf);
    this.raf = undefined;
    this.scroll.destroy()
    viewStorage.viewScroll = null
  }
}
