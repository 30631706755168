import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import PrestationsDetails from '../animations/prestations-details'
import DiscoverPrestations from '../animations/discover-prestations'

export default class ArchivePrestations extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      prestationsDetails: viewStorage.current.querySelector('.PrestationsDetails'),
      discover: viewStorage.current.querySelector('.Discover')
    }

    // Modules
    if (this.DOM.prestationsDetails) this.prestationsDetailsModule = new PrestationsDetails({ section: this.DOM.prestationsDetails })
    if (this.DOM.discover) this.discoverPrestationsModule = new DiscoverPrestations({ section: this.DOM.discover })
  }

  onLeaveCompleted() {
    if (this.prestationsDetaislModule) this.prestationsDetailsModule.destroy()
    if (this.discoverPrestationsModule) this.discoverPrestationsModule.destroy()
  }
}
