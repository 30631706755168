import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class Anchors {
  constructor() {
    // DOM
    this.DOM = {
      anchors: viewStorage.current.querySelectorAll('[data-anchor]')
    }

    if (!this.DOM.anchors && !this.DOM.anchors.length) return

    // Events
    this.onClick = this.onClick.bind(this)
    this.DOM.anchors.forEach(anchor => anchor.addEventListener('click', this.onClick))
  }

  onClick(e) {
    e && e.preventDefault()

    const hash = e.currentTarget.getAttribute('href')
    const relatedDOMElement = viewStorage.current.querySelector(hash)

    if (!relatedDOMElement) return

    gsap.to(window, {
      scrollTo: relatedDOMElement,
      duration: 1.5,
      ease: 'expo.inOut'
    })

    // const { container } = this.DOM
    // const { currentTarget } = e

    // // anchors active
    // if (container.querySelector('.--active')) container.querySelector('[data-anchor].--active').classList.remove('--active')
    // currentTarget.classList.add('--active')

    // const targetAnchor = currentTarget.getAttribute('href')
    // const targetOffset = currentTarget.dataset.offset

    // const { hasSmoothScroll, viewScroll } = viewStorage
    // if (hasSmoothScroll) viewScroll.scroll.scrollTo(targetAnchor, { offset: targetOffset ? targetOffset * -1 : 0, duration: 0.8, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2 })
    // else gsap.to(window, {duration: 1.8, scrollTo: { y: targetAnchor, offsetY: targetOffset ? targetOffset : 0, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })

  }

  destroy() {
    if (!this.DOM.anchors && !this.DOM.anchors.length) return

    this.DOM.anchors.forEach(anchor => anchor.removeEventListener('click', this.onClick))
  }
}
