import { Renderer } from '@unseenco/taxi'
// import { isDEVMODE, viewStorage } from '../_globals'

export default class PageContact extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {}

    // Modules
  }

  onLeaveCompleted() {}
}
