/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage, modulesStorage, recaptchaPublicKey } from './_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import { updateHeaderTheme } from './utils'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'

import { FormsManager } from '@wokine-agency/forms'

import Anchors from './modules/anchors'
import EBook from './modules/ebook'

const body = document.body;


/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(domStorage.body)
  viewStorage.viewInView = new viewInView(domStorage.body)

  // Intro
  GlobalIntro()

  // Add Class to body
  if (viewStorage.current.classList.contains('Contact')) body.classList.add('--Contact')

  // Update header theme
  updateHeaderTheme(viewStorage.current)

  // Anchors
  if (window.location.hash) {
    gsap.to(window, {
      scrollTo: {
        y: window.location.hash,
        autoKill: false
      },
      overwrite: 'all',
      ease: 'expo.inOut',
      duration: 1.8,
      delay: 5
    })
  } 

  // General modules
  modulesStorage.anchors = new Anchors()
  modulesStorage.ebook = new EBook()

  // Forms
  initForms({
    DOM: domStorage.body
  })
}

export const onEnter = (to, trigger) => {
  domStorage.body.classList.remove('--loading')

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()

  // Forms
  initForms({
    DOM: viewStorage.current
  })

  // Update header theme
  updateHeaderTheme(viewStorage.current)

  // Add Classs to body
  if (viewStorage.current.classList.contains('Contact')) body.classList.add('--Contact')
}

export const onEnterCompleted = (to, from, trigger) => {
  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': globalStorage.taxi.targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': globalStorage.taxi.targetLocation.href
    })
  }

  // Anchor
  if (globalStorage.taxi.targetLocation.hasHash) {
    if (document.querySelector(window.location.hash)) {
      gsap.to(window, {
        scrollTo: {
          y: window.location.hash,
          autoKill: false
        },
        overwrite: 'all',
        ease: 'expo.inOut',
        duration: 1.8,
      })
    }
  }

  // General modules
  modulesStorage.anchors = new Anchors()
  modulesStorage.ebook = new EBook()
}

export const onLeave = (from, trigger) => {
  if (viewStorage.hasInview) viewStorage.viewInView.destroy()
  if (viewStorage.hasPrllx)  viewStorage.viewPrllx.destroy()

  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  // Close Menu
  if (globalStorage.menuOpen) globalStorage.closeMobileMenu()

  // Remove active links
  const navLinks = domStorage.menu.querySelectorAll('.Nav .Link')

  // Remove Classs to body
  if (viewStorage.current.classList.contains('Contact')) body.classList.remove('--Contact')

  navLinks.forEach((link) => {
    if (link.href === globalStorage.taxi.targetLocation.raw) {
      link.classList.add('--active')
    } else {
      link.classList.remove('--active')
    }
  })

  // General modules
  if (modulesStorage.anchors) modulesStorage.anchors.destroy()
  if (modulesStorage.ebook) modulesStorage.ebook.destroy()
}

function initForms({DOM}) {
  let token = null;
  FormsManager.init({
    debug: isDEVMODE,
    clearOnSuccess: true,
    DOM, // Si non renseigné, c'est le "document" complet qui sera utilisé
    forms: {
      contact: {
        // events: {
        //   async init () {
        //     token = await getRecaptchaToken()
        //   }
        // },
        // hooks: {
        //   formData: (formData) => {
        //     formData.append('g-recaptcha-response', token)
        //     return formData
        //   }
        // }
      },
      newsletter: {
        events: {
          // async init () {
          //   token = await getRecaptchaToken()
          // }
        },
        hooks: {
          // formData: (formData) => {
          //   formData.append('g-recaptcha-response', token)
          //   return formData
          // }
        }
      },
      ebook: {
        events: {
          // async init () {
          //   token = await getRecaptchaToken()
          // }
        },
        hooks: {
          // formData: (formData) => {
          //   formData.append('g-recaptcha-response', token)
          //   return formData
          // }
        }
      }
    }
  });
}
