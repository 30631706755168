import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import ThematicsPrllx from '../modules/thematics-prllx'

export default class FrontPage extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.init()
  }

  init() {
    // DOM
    this.DOM = {}

    this.ctx = gsap.context((self) => {}, viewStorage.current)
    this.mm = gsap.matchMedia();

    this.initThematics()
    this.initTestimonies()
    this.initJournal()
  }

  initThematics() {
    this.ctx.add((self) => {
      const thematics = self.selector('section.Thematics')[0]
      if (thematics === undefined) return

      const thematicsPrllx = new ThematicsPrllx(thematics)

      return () => {
        thematicsPrllx.destroy()
      }
    })
  }

  initTestimonies() {
    this.ctx.add((self) => {
      const testimoniesSwiper = self.selector('section.Testimonies .swiper')[0]
      
      if (!testimoniesSwiper) return

      const testimoniesSwiperModule = new WknSwiper(testimoniesSwiper, { 
        slidesPerView : 'auto', 
        spaceBetween: 10
      })

      return () => {
        testimoniesSwiperModule.destroy()
      }
    })
  }

  initJournal() {
    this.ctx.add((self) => {
      const journal = self.selector('section.Journal')[0]
      if (journal === undefined) return

      const journalSwiper = new MobileSwiper(journal.querySelector('.swiper'))

      return () => {
        journalSwiper && journalSwiper.destroy()
      }
    })
  }

  onLeaveCompleted() {
    this.ctx && this.ctx.revert()
    this.mm && this.mm.revert()
  }
}
