import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import WknSwiper from '../modules/wkn-swiper'
import ThematicsPrllx from '../modules/thematics-prllx-presta'
import PrestationDetails from '../animations/prestation-details'

export default class SinglePrestation extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      prestationDetails: viewStorage.current.querySelector('.PrestationDetails'),
    }
  
    this.ctx = gsap.context((self) => {}, viewStorage.current)
    this.mm = gsap.matchMedia()

    if (this.DOM.prestationDetails) this.prestationDetailsModule = new PrestationDetails({ section: this.DOM.prestationDetails })
  
    this.initThematics()
    this.initNotes()
    this.initRelatedPrestations()
  }

  initThematics() {
    this.ctx.add((self) => {
      const thematics = self.selector('div.PageHeaderPrestation')[0]
      if (thematics === undefined) return

      const thematicsPrllx = new ThematicsPrllx(thematics)

      return () => {
        thematicsPrllx.destroy()
      }
    })
  }

  initNotes() {
    this.ctx.add((self) => {
      const section = self.selector('section.Notes')[0]
      if (section === undefined) return

      const notes = section.querySelectorAll('.Notes__card')

      notes.forEach(note => {

        note.ST = ScrollTrigger.create({
          trigger: note,
          start: 'top bottom+=10%',
          onEnter: () => {
            gsap.fromTo(note.querySelector('.inner'), {
              yPercent: 100,
              xPercent: gsap.utils.random([-20, -10, 10, 20]),
              rotate: 5
            }, {
              yPercent: 0,
              xPercent: 0,
              duration: 1.2,
              rotate: () => gsap.utils.random(-2, 2),
              ease: 'power4.out'
            })
          }
        })

      });

      return () => {
        notes.forEach(note => note.ST.kill(true))
      }
    })
  }

  initRelatedPrestations() {
    this.ctx.add((self) => {
      const relatedPrestationsSwiper = self.selector('section.Prestations .swiper')[0]

      if (!relatedPrestationsSwiper) return

      const relatedPrestationsSwiperModule = new WknSwiper(relatedPrestationsSwiper, { 
        slidesPerView: 1, 
        spaceBetween: 10,
        breakpoints: {
          768: {
            slidesPerView: 2, 
            spaceBetween: 50,
          }
        }
      })

      return () => {
        relatedPrestationsSwiperModule.destroy()
      }
    })
  }

  onLeaveCompleted() {
    this.ctx && this.ctx.revert()
    this.mm && this.mm.revert()
    
    if (this.prestationDetailsModule) this.prestationDetailsModule.destroy()
  }
}
