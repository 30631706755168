import { domStorage } from './_globals'

/**
 * Delay function.
 * @param {value} ms Millisecondes.
 * @param {int} max Max number.
 * @returns {function} function Delayed function.
 */
export function delay(ms) {
  let ctr
  let rej
  const p = new Promise((resolve, reject) => {
    ctr = setTimeout(resolve, ms)
    rej = reject
  })

  p.cancel = () => {
    clearTimeout(ctr)
    rej(Error('Cancelled'))
  }

  return p
}

/**
 * Random number.
 * @param {int} min Min number.
 * @param {int} max Max number.
 * @returns {int} Random between the two numbers.
 */
export function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Normalize value.
 * @param {int} value Min number.
 * @param {int} min Max number.
 * @param {int} max Max number.
 * @returns {int} Normalize between the two numbers.
 */
export function normalize(value, min, max) {
  return (value - min) / (max - min)
}

/**
* Returns a number whose value is limited to the given range.
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 * @param {int} value value
 * @param {int} min Min range
 * @param {int} max Max range
 * @returns {int} A number in the range [min, max]
 */
export function clamp(value, min, max) {
  return value < min ? min : value > max ? max : value
}

/**
 * Lerp value.
 * @param {int} v0 value 1.
 * @param {int} v1 value 2.
 * @param {int} t Time.
 * @returns {int} Lerp between.
 */
export function lerp(v0, v1, t) {
  return v0 * (1 - t) + v1 * t
}


/**
* Simulate Event.
* @param {node} elem Element
 */
export function simulateClick(elem) {

  const evt = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  })

  const canceled = !elem.dispatchEvent(evt)

}

/**
* MouseMoveEvents.
* @param {event} e MouseEvent
 */
export function mouseMovefn(e) {
  const xMouse = e.pageX - e.currentTarget.getBoundingClientRect().left - e.currentTarget.offsetWidth / 2
  const yMouse = e.pageY - window.pageYOffset - e.currentTarget.getBoundingClientRect().top - e.currentTarget.offsetHeight / 2
  const mouseElements = e.currentTarget.querySelectorAll('*[data-mouse-parallax]')
  mouseElements.forEach(elem => {
    const factor = elem.getAttribute('data-mouse-parallax')
    const xFinal = xMouse * factor
    const yFinal = yMouse * factor
    gsap.to(elem, { duration: 1.2, x: xFinal, y: yFinal })
  })
}

/**
* MouseMoveEvents.
* @param {event} e MouseEvent
* @returns {obj} an Object with positions [x, y]
 */
export function getMousePos(e) {
  let posx = 0
  let posy = 0
  
  if (!e) e = window.event
  if (e.pageX || e.pageY) {
    posx = e.pageX - window.scrollX
    posy = e.pageY - window.scrollY
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }

  return { x: posx, y: posy }
}

/**
* getPosition form top.
* @param {dom} element Dom Element
* @returns {obj} an Object with positions [x, y]
 */
export function getPosition(element) {
  let el = element
  let xPosition = 0
  let yPosition = 0

  while (el) {
    xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft
    yPosition += el.offsetTop - el.scrollTop + el.clientTop
    el = el.offsetParent
  }

  return { x: xPosition, y: yPosition }
}


/**
* Create Ajax URL
* @param {string} action action Element
* @param {params} params Obj Element
* @returns {string} an ajax url with action and params
 */
export const buildAjaxUrl = (action, params = {}) => {
  let url = `/wp-admin/admin-ajax.php?action=${action}`
  if (Object.keys(params).length) url += `&${Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')}`
  return url
}

/**
 * Touch devices
 */
export function isTouch() {
  if ('standalone' in navigator) {
    return true // iOS devices
  }

  const hasCoarse = window.matchMedia('(pointer: coarse)').matches

  if (hasCoarse) {
    return true
  }

  const hasPointer = window.matchMedia('(pointer: fine)').matches
  
  if (hasPointer) {
    return false // prioritize mouse control
  }

  // Otherwise, fall-back to older style mechanisms.
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

/**
 * Update full dimensions (100vh/100vw) with CSS variables
 */

export const getDocumentFullDimensions = () => {
  const windowWidth = document.documentElement.clientWidth * 0.01
  const windowHeight = document.documentElement.clientHeight * 0.01

  document.documentElement.style.setProperty('--vh', `${windowHeight}px`)
  document.documentElement.style.setProperty('--vw', `${windowWidth}px`)
}

/**
 * Update header themes
 */
export const updateHeaderTheme = (page) => {
  if (!domStorage.header) return

  // Special for Home
  page.dataset.header === 'about' ?
    domStorage.header.classList.add('--about') :
    domStorage.header.classList.remove('--about')

  // Dark
  page.dataset.header === 'dark' ?
    domStorage.header.classList.add('--dark') :
    domStorage.header.classList.remove('--dark')

  // Dark
  page.dataset.header === 'rose' ?
    domStorage.header.classList.add('--rose') :
    domStorage.header.classList.remove('--rose')
}
