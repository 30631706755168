import { domStorage } from "../_globals"
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Footer {
  constructor() {
    // DOM
    this.DOM = { el: domStorage.footer }
  }

  onResize() {}
}