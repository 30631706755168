import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'

export default class PageAbout
 extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page About`)
    if (isDEVMODE) console.log(viewStorage.current)

    // DOM
    this.DOM = {}

    this.ctx = gsap.context((self) => {}, viewStorage.current)
    this.mm = gsap.matchMedia();

    this.initPageheader()
    this.initOffice()
  }

  initPageheader() {
    if (isDEVMODE) console.log('Init Pageheader')

    this.ctx.add((self) => {
      const pageHeader = self.selector('.PageHeaderAbout')[0]
      if (pageHeader === undefined) return

      const gallerySteps = pageHeader.querySelectorAll('.PageHeaderAbout__gallery .step')

      let currentStep = 0
      const pageHeaderST =  ScrollTrigger.create({
        trigger: pageHeader,
        start: 'top top',
        end: 'bottom bottom',
        onUpdate: self => {
          const nextStep = Math.round(gsap.utils.interpolate([0, 1, 2], self.progress))
          if (currentStep != nextStep) {
            gallerySteps[currentStep].classList.remove('--active')
            currentStep = nextStep
            gallerySteps[currentStep].classList.add('--active')
          }
        }
      })

      return () => {
        pageHeaderST.kill(true)
      }
    })
  }

  initOffice() {
    if (isDEVMODE) console.log('Init Office')

    this.ctx.add((self) => {
      const office = self.selector('.Office')[0]
      if (office === undefined) return

      const officeVisual = office.querySelector('.Office__visual')
      const officeVisualWrapper = officeVisual.querySelector('.wrapper')

      const officeTL = gsap.timeline({
        paused: true,
        invalidateOnRefresh: true,
        defaults: {
          duration: 1,
          ease: 'none'
        },
        scrollTrigger: {
          trigger: officeVisualWrapper,
          start: 'center center',
          end: '100%',
          pin: true,
          scrub: true,
          invalidateOnRefresh: true
        }
      })

      officeTL.fromTo(officeVisual.querySelector('.visual__container'), 
      { width: () => officeVisualWrapper.offsetWidth, height: () => officeVisualWrapper.offsetHeight },
      { width: () => window.innerWidth - 40, height: () => window.innerHeight - 40 }, 0)

      officeTL.fromTo(officeVisual.querySelector('.visual'), { scale: 1.2 }, { scale: 1}, 0)

      return () => {
        officeTL.kill()
      }
    })
  }

  onLeaveCompleted() {
    this.ctx && this.ctx.revert()
    this.mm && this.mm.revert()
  }
}
