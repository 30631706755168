import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class Header {
  constructor() {
    if (!domStorage.header) return

    // DOM
    this.DOM = {
      el: domStorage.header,
      nav: domStorage.header.querySelector('.Nav'),
      navToggle: domStorage.header.querySelector('.NavToggle'),
      submenusItems: [...domStorage.header.querySelectorAll('li.--submenu')],
      submenusButtons: [...domStorage.header.querySelectorAll('li.--submenu > .Link')],
      submenus: [...domStorage.header.querySelectorAll('.NavSubmenus')]
    }

    // Params
    this.breakpoint = 768
    this.className = '--active'
    this.currentSubmenu = null

    // Events
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.openSubmenu = this.openSubmenu.bind(this)
    this.closeSubmenu = this.closeSubmenu.bind(this)
    this.closeOutsideSubmenu = this.closeOutsideSubmenu.bind(this)

    this.DOM.navToggle.addEventListener('click', () => globalStorage.menuOpen ? this.close() : this.open())
    domStorage.overlay.addEventListener('click', this.close)

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    this.DOM.submenusButtons.forEach(button => button.addEventListener('click', this.openSubmenu))

    // Observer
    this.setObservers()

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.windowWidthResize = window.innerWidth
  }

  /**
   * Events
   */

  // Mobile
  open(e) {
    e && e.preventDefault()

    // Update variables
    globalStorage.menuOpen = true

    // Update body className
    domStorage.body.classList.add('--show-menu')

    // Stop the smooth scroll
    if (viewStorage.hasSmoothScroll) viewStorage.viewScroll.scroll.stop()

    // Add attribute
    this.DOM.nav.setAttribute('data-lenis-prevent', true)
  }

  close(e) {
    e && e.preventDefault()

    // Update variables
    globalStorage.menuOpen = false

    // Update body className
    domStorage.body.classList.remove('--show-menu')

    // Close submenus
    this.closeSubmenu()

    // Restart the smooth scroll
    if (viewStorage.hasSmoothScroll) viewStorage.viewScroll.scroll.start()

    // Remove attribute
    this.DOM.nav.removeAttribute('data-lenis-prevent')
  }

  // Submenus
  openSubmenu(e) {
    e && e.preventDefault()

    // Update current submenu
    this.currentSubmenu = e.currentTarget.parentNode

    // Update current submenu classNames
    this.DOM.submenusItems.filter(item => { 
      item === this.currentSubmenu ? 
        item.classList.toggle(this.className) : 
        item.classList.remove(this.className) 
    })

    // Update body className depending of current submenu
    this.currentSubmenu.classList.contains(this.className) ? 
      domStorage.body.classList.add('--show-submenu') : 
      domStorage.body.classList.remove('--show-submenu')

    // Stop smooth scroll
    if (window.innerWidth >= this.breakpoint) {
      viewStorage.viewScroll.scroll.stop()
    }

    // Add event on document for click outside the currentSubmenu
    setTimeout(() => document.addEventListener('click', this.closeOutsideSubmenu), 150)
  }

  closeSubmenu(e) {
    e && e.preventDefault()

    if (!this.currentSubmenu) return

    // Update body className 
    domStorage.body.classList.remove('--show-submenu')

    // Remove className
    this.currentSubmenu.classList.remove(this.className)

    // Update current submenu
    this.currentSubmenu = null

    // Start smooth scroll
    if (window.innerWidth >= this.breakpoint) {
      viewStorage.viewScroll.scroll.start()
    }

    // Removing the event on document
    document.removeEventListener('click', this.clickOutside)
  }

  closeOutsideSubmenu(e) {
    e && e.stopPropagation()

    if (this.currentSubmenu && !this.currentSubmenu.contains(e.target)) this.closeSubmenu()
  }

  /**
   * Observer
   */

  setObservers() {
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: (self) => {
        // console.log('on up', self.scrollY())
        
        el.classList.remove('--hide')
        
        if (self.scrollY() <= 20) el.classList.remove('--scroll')
        },
      onDown: (self) => {
        // console.log('on down', self.scrollY())

        // Fix the iPhone issue with Safari
        if (self.scrollY() > 0 && !el.classList.contains('--hide')) {
          el.classList.add('--hide')
        }

        if (self.scrollY() > 20 && !el.classList.contains('scroll')) {
          el.classList.add('--scroll')
        }
      },
    })
  }

  /**
   * Resize
   */

  onResize() {
    clearTimeout(this.resizeTimeout)

    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
